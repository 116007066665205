import { HStack } from "@chakra-ui/react";

interface IVerticalMarket {
  [id: string]: {
    [id2: string]: any;
  };
}
const s3 = "https://ocv-web-icons.s3.us-east-2.amazonaws.com/";
const verticalMarkets: IVerticalMarket = {
  sheriff: {
    href: "https://thesheriffapp.com",
    alt: "The Sheriff App",
    img: s3 + "TheSheriffAppCom.png",
    width: "450px",
  },
  police: {
    href: "https://thepoliceapp.com",
    alt: "The Police App",
    img: s3 + "ThePoliceApp.png",
    width: "200px",
  },
  ema: {
    href: "https://myemaapp.com",
    alt: "My EMA App",
    img: s3 + "MyEMAApp.png",
    width: "200px",
  },
  fire: {
    href: "https://thefiredepartmentapp.com",
    alt: "The Fire Department App",
    img: s3 + "TheFireDepartment.png",
    width: "200px",
  },
  health: {
    href: "https://thepublichealthapp.com",
    alt: "The Public Health App",
    img: s3 + "ThePublicHealthApp.png",
    width: "200px",
  },
  ocv: {
    href: "https://ocvapps.com",
    alt: "OCV Apps",
    img: s3 + "ocvLLCWhite.png",
    width: "150px",
  },
  ocvBlue: {
    href: "https://ocvapps.com",
    alt: "OCV Apps",
    img: s3 + "OCVBlue.png",
    width: "150px",
  },
  gov: {
    href: "https://www.thegovapp.com/",
    alt: "Gov Apps",
    img: s3 + "GovApps.png",
    width: "200px",
  },
  utilities: {
    href: "https://theutilityapp.com/",
    alt: "Gov Apps",
    img: s3 + "UtilitiesLogo.png",
    width: "75px",
  },
};

export default function VerticalMarket({
  footerConfig,
}: Record<string, any>): JSX.Element {
  return (
    <HStack
      display="flex"
      id="verticalMarket"
      align={["center", "center", "center", "center"]}
    >
      <div style={{ lineHeight: "2rem" }}>
        {footerConfig?.vertical && footerConfig.vertical !== "sheriff" && (
          <p>BUILT BY:</p>
        )}
        <p> v.{process.env.REACT_APP_VERSION}</p>
      </div>
      <a
        rel="noopener noreferrer"
        href={
          verticalMarkets[footerConfig?.vertical]
            ? verticalMarkets[footerConfig?.vertical]?.href
            : verticalMarkets["ocv"]?.href
        }
        target={"_blank"}
      >
        <img
          src={verticalMarkets[footerConfig?.vertical]?.img}
          alt={verticalMarkets[footerConfig?.vertical]?.alt}
          width={verticalMarkets[footerConfig?.vertical]?.width}
        />
      </a>
    </HStack>
  );
}
