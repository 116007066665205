import React from "react";
import { HStack } from "@chakra-ui/react";
export default function CopyRight({
  footerConfig,
}: Record<string, any>): JSX.Element {
  return (
    <HStack
      display="flex"
      align={["center", "center", "center", "start"]}
      id="copyright"
      style={{ lineHeight: "1.5rem" }}
    >
      <div>
        {`COPYRIGHT © ${new Date().getFullYear()} OCV, LLC `}
        {footerConfig?.title && `& ${footerConfig.title.toUpperCase()}`}
      </div>
    </HStack>
  );
}
