import React, { useState } from "react";
import { Navbar } from "./views/Navbar/Navbar";
import { Outlet } from "react-router";
import Footer from "./views/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import CollapsableBanner from "./views/CollapsableBanner/CollapsableBanner";

export const Layout = ({ manifest }: Record<string, any>): JSX.Element => {
  // initialize the banner exists to false
  const [bannerExists, setBannerExists] = useState(false);
  // check if manifest has the collapsableBanner view before loading the page
  if (
    !bannerExists &&
    manifest.homeOrder.collapsableBanner &&
    manifest.views.collapsableBanner
  ) {
    setBannerExists(true);
  }

  return (
    <React.Fragment>
      <div
        id="wrapper"
        style={{
          position: "sticky",
          top: "0px",
          zIndex: "999",
          height: "100px",
          width: "100%",
        }}
      >
        {bannerExists && <CollapsableBanner manifestData={manifest} />}
        <Navbar manifestData={manifest} />
      </div>
      <Outlet />
      <ScrollToTop />
      <Footer manifestData={manifest} />
    </React.Fragment>
  );
};
