import { useMediaQuery, Box, VStack, HStack, Text } from "@chakra-ui/react";
import styled from "styled-components/macro";

export const Icon = styled.img`
  height: 140px;
  width: 140px;
  margin: 30px;
  @media (max-width: 768px) {
    height: 70px;
    width: 70px;
    margin: 15px;
  }
  @media (max-width: 480px) {
    height: 45px;
    width: 45px;
    margin: 10px;
  }
  @media (max-width: 300px) {
    height: 30px;
    width: 30px;
    margin: 8px;
  }
`;

export default function ErrorPageDetail(): JSX.Element {
  const s3 =
    "https://ocv-web-icons.s3.us-east-2.amazonaws.com/pageNotFound.svg";

  const [isLessThan300px] = useMediaQuery("(max-width: 300px)");
  const [isLessThan480px] = useMediaQuery("(max-width: 480px)");
  const [isLessThan768px] = useMediaQuery("(max-width: 768px)");
  const [isLessThan992px] = useMediaQuery("(max-width: 992px)");

  return (
    <section
      style={{
        width: "100%",
        height: "100%",
        gap: "1rem",
        padding: "2rem 0rem",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      <Box
        width={
          isLessThan300px
            ? "200px"
            : isLessThan480px
            ? "280px"
            : isLessThan768px
            ? "600px"
            : isLessThan992px
            ? "800px"
            : "900px"
        }
      >
        <HStack>
          <Box
            width={
              isLessThan300px
                ? "50px"
                : isLessThan480px
                ? "75px"
                : isLessThan768px
                ? "100px"
                : isLessThan992px
                ? "200px"
                : "300px"
            }
          >
            <Icon
              src={s3}
              alt="Caution Symbol"
            />
          </Box>
          <VStack>
            <Box
              width={
                isLessThan300px
                  ? "150px"
                  : isLessThan480px
                  ? "200px"
                  : isLessThan768px
                  ? "400px"
                  : isLessThan992px
                  ? "600px"
                  : "700px"
              }
            >
              <Text
                m="4"
                color="black"
                fontFamily="Fjalla One"
                fontSize={["20px", "24px", "48px", "64px", "64px"]}
              >
                Oops!
              </Text>
              <Text
                m="4"
                color="black"
                fontFamily="Roboto, sans-serif"
                fontSize={["14px", "14px", "28px", "38px", "38px"]}
              >
                We can not seem to find the page you are looking for. Try to
                refresh your browser or go back to the previous page.
              </Text>
            </Box>
          </VStack>
        </HStack>
      </Box>
    </section>
  );
}
