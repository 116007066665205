import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const useFetchQuery = (queryKey: string, link: string): any => {
  // get data
  async function getFAQ() {
    const { data } = await axios.get(link);
    return data;
  }

  const { data } = useQuery([{ queryKey }], () => getFAQ());
  return { data };
};
