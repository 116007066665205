import React, { useState, useEffect } from "react";
import { Box, Image } from "@chakra-ui/react";
import SliderOverlay from "./SliderOverlay";

export default function SliderMultiMedia({
  sliderConfig,
  anchorID,
}: Record<string, any>): JSX.Element {
  const mediaItems = sliderConfig.media;

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % mediaItems.length);
    }, sliderConfig.interval);

    return () => clearInterval(timer);
  }, [mediaItems, sliderConfig.interval]);

  return (
    <section
      id={anchorID}
      data-testid="sliderMultiMediaSection"
      style={{
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {mediaItems.map((item: any, index: any) => (
        <Box
          key={index}
          data-testid="mediaBox"
          display={index === activeIndex ? "block" : "none"}
          transition="translate 300 ms ease-in-out"
          sx={{
            backgroundColor: item.bgColor,
            opacity: item.opacity,
            height: "100%",
            width: "100%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "relative",
              paddingBottom:
                "56.25%" /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
              height: 0,
              overflow: "hidden",
            }}
          >
            {item.mediaType === "image" ? (
              <Image
                data-testid="image"
                src={item.mediaURL}
                alt={item.mediaAlt || ""}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <video
                data-testid="video"
                src={item.mediaURL}
                autoPlay
                loop
                muted
                disableRemotePlayback
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )}
          </div>
        </Box>
      ))}
      {sliderConfig.showOverlay && (
        <SliderOverlay sliderConfig={sliderConfig} />
      )}
    </section>
  );
}
