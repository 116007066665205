import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import * as S from "./styles";
import styled from "styled-components/macro";
import { CloseIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";

import { useFetchQuery } from "../../hooks/useFetchQuery";
import CollapsableBannerDisplay from "./CollapsableBannerDisplay";

export const BlogLink = styled(NavLink)`
  &:hover {
    text-decoration: underline;
  }
`;

export default function CollapsableBanner({
  manifestData,
}: Readonly<Record<string, any>>): JSX.Element {
  const [show, setShow] = useState(true); // close banner
  const [activeIndex, setActiveIndex] = useState(0); // marquee

  const { views } = manifestData;
  const viewData = views.collapsableBanner;

  const featureType = manifestData.features?.[viewData?.feature].type; // a newsDigestList or blogList

  //get data for banner
  const queryKey = "collapsableBanner";
  const link = manifestData.features?.[viewData?.feature].url;

  const { data } = useFetchQuery(queryKey, link);
  const bannerData = data;

  const feature = viewData.feature;
  const inactiveMessage = viewData.inactiveMessage; //shows when there are no active alerts
  const config = viewData.config;
  const interval = config.interval; // time before rotating
  const bannerLimit = config.bannerLimit; // number of items for the banner
  //colors and fonts
  const backgroundColor = config.backgroundColor;
  const textColor = config.textColor;
  const fontFamily = config.fontFamily;
  const fontSize = config.fontSize;

  function FilterActiveNotification(item: any) {
    // check for push type and active
    if (item.type == 5) {
      if (item.active == 1) {
        return true;
      } else return false;
    } else return true;
  }

  // for digest data
  function HandleDigest(item: any, index: number) {
    let editedContent;
    let editedTitle;
    let ID; // determined by type
    if (item.type == 5 && item.active == 0) {
      editedContent = "";
      editedTitle = inactiveMessage;
    } else {
      if (item.type === 1) {
        ID = item._id?.$id;
      } else if (item.type === 4) {
        ID = item.eventID;
      } else {
        ID = item.epoch;
      }

      if (item.type == 4) {
        // calendar has a specific title
        editedTitle = item.calendarTitle;
      } else editedTitle = item.title;

      if (item.type != 1 && item.type != 5) {
        //remove paragraph and new line tags
        editedContent = item.content
          .replaceAll("<p>", "")
          .replaceAll("<br/>", " ")
          .replaceAll("</p>", "");
      } else editedContent = "";
    }

    return {
      index: index,
      type: item?.type,
      active: item.active,
      id: ID,
      title: editedTitle,
      content: editedContent,
      image: item.images?.[index]?.large,
    };
  }

  // for blogdata
  function HandleBlog(item: any, index: number) {
    //remove paragraph and new line tags
    const editedContent = item.content
      ?.replaceAll("<p>", "")
      ?.replaceAll("<br/>", " ")
      ?.replaceAll("</p>", "");

    return {
      index: index,
      type: item?.type,
      active: item.active,
      id: item._id?.$id,
      title: item.title,
      content: editedContent,
      image: item.images?.[index]?.large,
    };
  }

  let bannerContent: any;
  // check the featureType for blog
  if (featureType === "blogList") {
    bannerContent = bannerData
      .slice(0, bannerLimit)
      ?.map((item: any, index: number) => {
        return HandleBlog(item, index);
      });
  }
  // check the featureType for digest
  if (featureType === "newsDigestList") {
    bannerContent = bannerData
      .slice(0, bannerLimit)
      .filter(FilterActiveNotification)
      ?.map((item: any, index: number) => {
        return HandleDigest(item, index);
      });
  }

  // scrolling marquee
  const updateIndex = (newIndex: number) => {
    // check if bannerLimit is equal to banner content after the filter
    let newLimit = 0;
    if (bannerLimit < bannerContent.length) {
      newLimit = bannerContent.length;
    } else if (bannerLimit == bannerContent.length) {
      newLimit = bannerLimit;
    } else newLimit = 1; // there is a single Inactive Message

    if (newIndex < 0) {
      newIndex = newLimit - 1;
    } else if (newIndex >= newLimit) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    // marquee

    const timer = setInterval(() => {
      updateIndex(activeIndex + 1);
    }, interval);

    return () => clearInterval(timer);
  });

  // close banner when x is clicked
  const handleClick = () => {
    setShow(!show);
  };

  // when the page loads the banner is set to show
  // when X is clicked show = false, section will no longer display
  if (!show) return <></>;
  if (bannerData.length < 1) return <></>;
  return (
    show && (
      <S.StyledBanner $backgroundColor={backgroundColor}>
        <CollapsableBannerDisplay
          featureType={featureType}
          feature={feature}
          bannerContent={bannerContent}
          bannerLimit={bannerLimit}
          activeIndex={activeIndex}
          inactiveMessage={inactiveMessage}
          backgroundColor={backgroundColor}
          color={textColor}
          fontFamilyTitle={fontFamily}
          fontSizeTitle={fontSize}
        />

        <IconButton
          aria-label="Close Button"
          onClick={handleClick}
          backgroundColor={backgroundColor}
          color={textColor}
          size="md"
          position="fixed"
          right="10px"
          cursor="ponter"
          fontSize="18px"
          icon={<CloseIcon fontSize="18px" />}
        />
      </S.StyledBanner>
    )
  );
}
