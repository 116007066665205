import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Link, VStack } from "@chakra-ui/react";

export const Item = styled.h2`
  text-transform: uppercase;
  font-size: 2rem;
  font-family: Fjalla One;
  text-align: left;
`;

export const External = styled(Link)`
  line-height: 2rem;
  &:hover {
    text-decoration: underline;
  }
`;
export const Internal = styled(NavLink)`
  line-height: 2rem;
  &:hover {
    text-decoration: underline;
  }
`;

export default function FooterResources({
  footerConfig,
}: Record<string, any>): JSX.Element {
  const config = footerConfig;

  return (
    <VStack
      id="footerResources"
      display="flex"
      align={["center", "center", "center", "start"]}
    >
      <Item>{footerConfig?.item2?.title}</Item>

      {config?.item2?.options?.map((option: any, index: number) => (
        <div key={option + index}>
          {option?.feature && (
            <Internal to={option?.feature}>{option?.featureText}</Internal>
          )}
          {option?.url && (
            <External
              href={option?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {option?.urlText}
            </External>
          )}
        </div>
      ))}
    </VStack>
  );
}
