import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { Text, Box } from "@chakra-ui/react";

interface Props {
  $fontSize?: string;
  $color?: string;
}

export const TitleText = styled(Text)<Props>`
  font-size: ${({ $fontSize }) => $fontSize};
  @media (max-width: 430px) {
    font-size: 20px;
  }
`;

export const BlogLink = styled(NavLink)<Props>`
  &:hover {
    text-decoration: underline;
    color: ${({ $color }) => $color};
  }
`;
export default function CollapsableBannerDisplay({
  feature,
  bannerContent,
  activeIndex,
  inactiveMessage,
  backgroundColor,
  color,
  fontFamilyTitle,
  fontSizeTitle,
}: Readonly<Record<string, any>>): JSX.Element {
  return (
    <Box
      data-testid="BannerContainer"
      width="100%"
      display="flex"
    >
      {bannerContent.map((item: any, index: number) => (
        <Box
          data-testid="BannerBox"
          width="100%"
          key={item.title}
          padding="8px"
          display={index === activeIndex ? "block" : "none"}
        >
          <BlogLink
            aria-label="Link to detail page"
            $color={color}
            to={{
              pathname: `/${feature}/${bannerContent[index].id}`,
            }}
            as={NavLink}
          >
            <TitleText
              data-testid="bannerTitle"
              color={color}
              fontFamily={fontFamilyTitle}
              $fontSize={fontSizeTitle}
            >
              {bannerContent[index].title}
            </TitleText>
            <Text
              data-testid="bannerViewDetail"
              color={color}
              fontFamily="Roboto"
              fontSize="16px"
              style={{ textDecoration: "underline" }}
            >
              View Details
            </Text>
          </BlogLink>
        </Box>
      ))}
      {bannerContent.length == 0 && (
        <Box
          data-testid="Inactive Message"
          width="100%"
          backgroundColor={backgroundColor}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignContent="center"
        >
          <TitleText
            data-testid="Inactive Text"
            color={color}
            fontFamily={fontFamilyTitle}
            $fontSize={fontSizeTitle}
          >
            {inactiveMessage}
          </TitleText>
        </Box>
      )}
    </Box>
  );
}
