import React from "react";
import styled from "styled-components";
import { Link, HStack } from "@chakra-ui/react";

export const External = styled(Link)`
  line-height: 2rem;
  padding: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
`;

export default function AccessibilityPolicies(): JSX.Element {
  return (
    <HStack
      wrap="wrap"
      display="flex"
      align={["center", "center", "center", "start"]}
    >
      <div>
        <External
          className="external-link"
          href="https://myocv.com/accessibility"
          target="_blank"
          rel="noopener noreferrer"
        >
          Accessibility
        </External>
      </div>
      <div>
        <External
          className="external-link"
          href="https://myocv.com/eula"
          target="_blank"
          rel="noopener noreferrer"
        >
          EULA
        </External>
      </div>
      <div>
        <External
          className="external-link"
          href="https://myocv.com/privacyPolicy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </External>
      </div>
      <div>
        <External
          className="external-link"
          href="https://myocv.com/translate"
          target="_blank"
          rel="noopener noreferrer"
        >
          Translation
        </External>
      </div>
    </HStack>
  );
}
