import React from "react";
import styled from "styled-components";
import {
  Box,
  Link,
  SimpleGrid,
  Spacer,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import Contact from "../../components/Contact/Contact";
import FooterResources from "../../components/FooterResources/FooterResources";
import SocialMedia from "../../components/SocialMedia/SocialMedia";

import CopyRight from "../../components/CopyRight/CopyRight";
import AccessibilityPolicies from "../../components/AccessibilityPolicies/AccessibilityPolicies";
import VerticalMarket from "../../components/VerticalMarket/VerticalMarket";

export const External = styled(Link)`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.1rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export default function Footer(props: Record<string, any>): JSX.Element {
  const { manifestData } = props;

  const footerConfig = manifestData?.views?.footer?.config;
  const primary = manifestData?.stylesheet?.colors?.primary;
  const tertiary = manifestData?.stylesheet?.colors?.tertiary;

  return (
    <footer id="OCV-footer">
      <Box
        bgColor={primary}
        color="white"
        py="1rem"
        px="3rem"
        borderTopWidth="4px"
        borderTopColor={tertiary}
      >
        <SimpleGrid
          columns={[1, 1, 1, 4]}
          gap={["1rem", "1rem", "1rem", "3rem"]}
        >
          <Contact footerConfig={footerConfig} />
          <FooterResources footerConfig={footerConfig} />
          <Spacer />
          <SocialMedia footerConfig={footerConfig} />
        </SimpleGrid>
        <Grid
          templateColumns="repeat(4, 1fr)"
          marginTop="3rem"
          display={["flex", "flex", "flex", "grid"]}
          flexWrap="wrap"
          gap={6}
        >
          <GridItem
            colSpan={1}
            display="flex"
          >
            <CopyRight footerConfig={footerConfig} />
          </GridItem>
          <GridItem
            colSpan={2}
            display="flex"
          >
            <AccessibilityPolicies />
          </GridItem>
          <GridItem
            colSpan={1}
            display="flex"
          >
            <VerticalMarket footerConfig={footerConfig} />
          </GridItem>
        </Grid>
      </Box>
    </footer>
  );
}
