import styled from "styled-components/macro";

interface Props {
  $backgroundColor?: string;
}

export const StyledBanner = styled.header<Props>`
  height: 100px;
  maxwidth: 1100px;
  widh: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
`;
