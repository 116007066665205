import { Link, VStack } from "@chakra-ui/react";
import styled from "styled-components";

export const Item = styled.h2`
  text-transform: uppercase;
  font-size: 2rem;
  font-family: Fjalla One;
  text-align: left;
`;

export const External = styled(Link)`
  line-height: 2rem;
`;

export default function Contact({
  footerConfig,
}: Record<string, any>): JSX.Element {
  return (
    <VStack
      id="contact"
      display="flex"
      align={["center", "center", "center", "start"]}
    >
      <Item data-testid="Item 1 Heading">{footerConfig?.item1?.title}</Item>

      {footerConfig?.item1?.address && (
        <External
          title="Click to open on Google Maps"
          rel="noopener noreferrer"
          target="_blank"
          href={`http://maps.google.com/?q=${footerConfig?.item1?.address}`}
          dangerouslySetInnerHTML={{
            __html: footerConfig?.item1?.address,
          }}
        />
      )}

      <div>
        <External
          href={`tel:${footerConfig?.item1?.phone}`}
          title={`Call us at ${footerConfig?.item1?.phone}`}
        >
          {footerConfig?.item1?.phoneName}
          {footerConfig?.item1?.phone}
        </External>
      </div>

      {footerConfig?.item1?.phone2 ? (
        <div>
          <External
            href={`tel:${footerConfig?.item1?.phone2}`}
            title={`Call us at ${footerConfig?.item1?.phone2}`}
          >
            {footerConfig?.item1?.phoneName2} {footerConfig?.item1?.phone2}
          </External>
        </div>
      ) : null}
      <div>
        <External
          href={`mailto:${footerConfig?.item1?.email}`}
          title={`Email us at ${footerConfig?.item1?.email}`}
        >
          {footerConfig?.item1?.email}
        </External>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: footerConfig?.item1?.businessHours,
        }}
        style={{ lineHeight: "1.5rem" }}
      />
    </VStack>
  );
}
