/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Slider.css";
import { useMediaQuery } from "@chakra-ui/react";

// TODO: Add a way of navigating through the images in the slider
interface Media {
  mediaURL?: string;
  mediaAlt?: string;
  mediaLink?: string;
  mediaMobile?: string;
}

export default function Slider({
  sliderConfig,
  anchorID,
}: Record<string, any>): JSX.Element {
  const [isLessThan768px] = useMediaQuery("(max-width: 768px)");

  const images =
    sliderConfig?.media?.map((media: Media) => {
      return {
        original: media.mediaURL,
        originalAlt: media.mediaAlt,
        originalClass: "SliderDiv",
        link: media.mediaLink,
        mobile: media.mediaMobile,
      };
    }) ?? [];

  const handleClick = (image: Record<string, any>) => {
    if (image.link) {
      window.open(image.link, "_blank", "noopener,noreferrer");
    }
  };

  const imagesDesktop = images.map((image: Record<string, any>) => ({
    ...image,
    renderItem: () => (
      <img
        key={image.original}
        className={image.link ? "" : "withoutPointer"}
        style={{ width: "100%" }}
        src={image.original}
        alt={image.originalAlt}
        onClick={() => handleClick(image)}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0} // Makes the image focusable via keyboard
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            handleClick(image); // Trigger the same action on Enter or Space
          }
        }}
      />
    ),
  }));
  const imagesMobile = images.map((image: Record<string, any>) => ({
    ...image,
    renderItem: () =>
      image.link ? (
        <a
          key={image.original}
          href={image.link}
          target="_blank"
          rel="noopener noreferrer"
          title={image.link}
        >
          <img
            style={{ width: "100%" }}
            src={image?.mobile ? image?.mobile : image.original}
            alt={image.originalAlt}
          />
        </a>
      ) : (
        <img
          key={image.original}
          className="withoutPointer"
          style={{ width: "100%" }}
          src={image?.mobile ? image?.mobile : image.original}
          alt={image.originalAlt}
        />
      ),
  }));

  return (
    <section
      id={anchorID}
      data-testid="slider-section"
    >
      <ImageGallery
        items={isLessThan768px ? imagesMobile : imagesDesktop}
        showPlayButton={false}
        showFullscreenButton={false}
        autoPlay={true}
        slideInterval={sliderConfig.interval ?? 5000}
        showThumbnails={false}
        showNav={false}
        lazyLoad={true}
      />
    </section>
  );
}
