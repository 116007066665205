import { chakra, Image, Text } from "@chakra-ui/react";
import "./SliderMultiMedia.css";

const OverlayText = chakra(Text, {
  baseStyle: {
    alignContent: "center",
    fontWeight: "400",
    fontFamily: "Fjalla One, sans-serif",
    fontSize: ["28px", "48px", "48px", "64x", "64px"],
    whiteSpace: "break-spaces",
    overflow: "hidden",
  },
});

const OverlaySubText = chakra(Text, {
  baseStyle: {
    alignContent: "center",
    fontWeight: "400",
    fontFamily: "Roboto, sans-serif",
    fontSize: ["18px", "18px", "24px", "28px", "48x"],
    display: ["none", "none", "block", "block", "block"],
    whiteSpace: "break-spaces",
    overflow: "hidden",
  },
});

export default function SliderOverlay({
  sliderConfig,
}: Record<string, any>): JSX.Element {
  const overlayOptions = sliderConfig.overlayOptions;

  // Check if showOverlay is true
  if (!sliderConfig.showOverlay) {
    return <></>;
  }

  const overlayClass = `overlay ${
    overlayOptions.overlayPosition === "center" ? "overlay-center" : ""
  } ${overlayOptions.overlayPosition === "top-left" ? "overlay-top-left" : ""}
  ${overlayOptions.overlayPosition === "top-right" ? "overlay-top-right" : ""}
  ${
    overlayOptions.overlayPosition === "top-center" ? "overlay-top-center" : ""
  } ${
    overlayOptions.overlayPosition === "bottom-left"
      ? "overlay-bottom-left"
      : ""
  }
  ${
    overlayOptions.overlayPosition === "bottom-right"
      ? "overlay-bottom-right"
      : ""
  }
  ${
    overlayOptions.overlayPosition === "bottom-center"
      ? "overlay-bottom-center"
      : ""
  }`;

  return (
    <div className={overlayClass}>
      {overlayOptions.overlayType === "text" && (
        <>
          <OverlayText color={overlayOptions.overlayTextColor}>
            {overlayOptions.overlayText}
          </OverlayText>
          {overlayOptions.overlayType != null && (
            <OverlaySubText color={overlayOptions.overlayTextColor}>
              {overlayOptions.overlaySubText}
            </OverlaySubText>
          )}
        </>
      )}

      {overlayOptions.overlayType === "image" && (
        <Image
          maxHeight={overlayOptions.maxHeight}
          margin="10px"
          src={overlayOptions.overlayImageURL}
          alt={overlayOptions.overlayImageAlt}
        />
      )}
      {overlayOptions.overlayType === "both" && (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: overlayOptions.flexDirection || "row",
          }}
        >
          <OverlayText color={overlayOptions.overlayTextColor}>
            {overlayOptions.overlayText}
          </OverlayText>
          {overlayOptions.overlayType != null && (
            <OverlaySubText color={overlayOptions.overlayTextColor}>
              {overlayOptions.overlaySubText}
            </OverlaySubText>
          )}
          <Image
            maxHeight={overlayOptions.maxHeight}
            margin="10px"
            src={overlayOptions.overlayImageURL}
            alt={overlayOptions.overlayImageAlt}
          />
        </div>
      )}
    </div>
  );
}
