import { ExternalLink, StyledLink, StyledAnchor } from "../Links/Links";
import * as S from "./styles";
import { Dropdown } from "./Dropdown/Dropdown";
import {
  SocialIcons,
  CustomSocialIcon,
} from "../../../components/SocialIcons/SocialIcons";
import { Image } from "@chakra-ui/react";
import styled from "styled-components";
import { OCVSearchButton } from "../../../components/AlgoliaSearch/OCVSearchButton";

export const StyledHeading = styled.h1`
  font-family: Fjalla One, sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  font-size: 2rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export function DesktopNav({ manifestData }: Record<string, any>): JSX.Element {
  const images = manifestData?.["stylesheet"]["images"];
  const featuresList = manifestData?.["features"];
  const anchorList = manifestData?.["views"];
  const config: any = manifestData?.views?.navbar?.config;
  const primaryColor = manifestData?.["stylesheet"]["colors"]["primary"];
  const tertiaryColor = manifestData?.["stylesheet"]["colors"]["tertiary"];

  const colors = {
    primaryColor,
    tertiaryColor,
  };
  const scrollToAnchor = (anchor: any) => {
    const anchorElement = document.getElementById(anchor);

    if (anchorElement) {
      const navbarHeight = 10; // Set the height of your navbar here
      const offsetPosition = anchorElement.offsetTop - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };
  if (!config) return <p>Loading..</p>;
  return (
    config && (
      <S.StyledNavbar
        $primary={colors.primaryColor}
        $tertiary={colors.tertiaryColor}
      >
        <S.NavDiv>
          <StyledLink to="/">
            <Image
              src={images?.["titleImage"]?.["url"]}
              alt={images?.["titleImage"]?.["altText"]}
              title="Go to Homepage"
              maxWidth={config?.imgWidth ? config?.imgWidth : "75px"}
              verticalAlign="middle"
              display="inline"
            />
            {config?.title && (
              <StyledHeading
                dangerouslySetInnerHTML={{ __html: config?.title }}
              />
            )}
          </StyledLink>
        </S.NavDiv>
        <S.Navigation aria-labelledby="primaryMenu">
          <S.NavList
            id="primaryMenu"
            role="menu"
          >
            {config.anchors?.map((anchor: any, index: number) => (
              <S.NavListItem
                key={anchor + index}
                $primary={colors.primaryColor}
                role="menuitem"
                onClick={() => scrollToAnchor(anchor)}
              >
                <StyledAnchor tabIndex={0}>
                  {anchorList[anchor].anchorTitle}
                </StyledAnchor>
              </S.NavListItem>
            ))}
            {config.items?.map((item: any, index: number) => (
              <S.NavListItem
                $type={featuresList[item].type}
                key={item + index}
                $primary={colors.primaryColor}
                role="menuitem"
              >
                {featuresList[item].type === "submenu" ||
                featuresList[item].type === "megaMenu" ? (
                  <Dropdown
                    featuresList={featuresList}
                    item={item}
                    index={index}
                    colors={colors}
                    tabIndex={0}
                  />
                ) : featuresList[item]?.external === true ? (
                  <ExternalLink
                    href={featuresList[item].url}
                    target="_blank"
                    rel="noopener noreferrer"
                    tabIndex={0}
                  >
                    {featuresList[item].title}
                  </ExternalLink>
                ) : (
                  <StyledLink
                    to={item}
                    tabIndex={0}
                  >
                    {featuresList[item].title}
                  </StyledLink>
                )}
              </S.NavListItem>
            ))}
          </S.NavList>
        </S.Navigation>
        <S.NavDiv>
          {config?.socialMedia?.map(
            (
              item: {
                icon: string;
                url: string;
                name: string;
                customIcon: string;
              },
              index: number
            ) => (
              <ExternalLink
                href={item.url}
                key={item.icon + index}
                target="_blank"
                aria-label={`Link to ${item?.name}`}
              >
                {item.customIcon
                  ? CustomSocialIcon(item.customIcon, "22px", item?.name)
                  : SocialIcons(item.icon, "22px")}
              </ExternalLink>
            )
          )}
          {config.hideSearch !== true &&
            config.hideSearch !== undefined &&
            config.hideSearch !== null && (
              <OCVSearchButton ocvsize={22}></OCVSearchButton>
            )}
        </S.NavDiv>
      </S.StyledNavbar>
    )
  );
}
