import React from "react";
import styled from "styled-components";
import { HStack, VStack, Link, Button } from "@chakra-ui/react";
import {
  SocialIcons,
  CustomSocialIcon,
} from "../../components/SocialIcons/SocialIcons";

export const Item = styled.h2`
  text-transform: uppercase;
  font-size: 2rem;
  font-family: Fjalla One;
  text-align: left;
`;

export const External = styled(Link)`
  line-height: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  align-content: start;
`;

export const DownloadButton = styled(Button)`
  borderwidth: 1px;
  font-size: 1rem;
  width: 15rem;
`;

export default function CopyWrite({
  footerConfig,
}: Record<string, any>): JSX.Element {
  const config = footerConfig;
  return (
    <VStack
      display="flex"
      alignContent="end"
      id="socialMedia"
    >
      <Item data-testid="Item 3 Heading">{footerConfig?.item3?.title}</Item>
      {config?.item3?.buttonLink && (
        <Link
          href={config?.item3?.buttonLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadButton
            bgColor={config?.item3?.buttonColor}
            color={config?.item3?.buttonTextColor}
            borderColor={config?.item3?.buttonBorderColor}
          >
            {config?.item3?.buttonText.toUpperCase()}
          </DownloadButton>
        </Link>
      )}
      <HStack
        marginTop="10px"
        align="center"
      >
        {config?.socialMedia?.map(
          (
            item: {
              icon: string;
              url: string;
              name: string;
              customIcon?: string;
            },
            index: number
          ) => (
            <Link
              href={item.url}
              key={item.icon + index}
              target="_blank"
              aria-label={`Link to ${item?.name}`}
            >
              {item.customIcon
                ? CustomSocialIcon(item.customIcon, "22px", item?.name)
                : SocialIcons(item.icon, "22px")}
            </Link>
          )
        )}
      </HStack>
    </VStack>
  );
}
